import { EspaceData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../components/View';
import Form from '../../../containers/Admin/Form';
import params from '../../../params/boutique/index.json';
import TemplateAdmin from '../../../templates/admin';
import requireUser from '../../../utils/requireUser';

const PageAdminBoutiquesCreate: FC<PageProps> = ({ location }) => (
  <TemplateAdmin>
    <CMSView>
      <Form
        itemPathnamePrefix="/admin/espaces/"
        model={new EspaceData({ params })}
        name="espace"
        type="create"
      />
    </CMSView>
  </TemplateAdmin>
);

export default requireUser(PageAdminBoutiquesCreate, 'admin');
